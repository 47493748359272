import React, { useState, useEffect, useRef } from 'react';
import { Container, Button, TableContainer, Paper, IconButton, Table, TableHead, FormControl, InputLabel, MenuItem, Select, TableRow, TableCell, TableBody, Dialog, DialogContent, TextField, Autocomplete, CircularProgress, Snackbar, DialogTitle, Box, Stack, useMediaQuery, Typography, ButtonGroup, Grid, Avatar, Divider, DialogActions } from '@mui/material';
import { Edit, Delete, Check, Close, RemoveRedEye, Message } from '@mui/icons-material'

import { useNavigate } from 'react-router-dom';
import { Editor } from "@tinymce/tinymce-react"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';

import { fetchData, addData, deleteData, updateData } from '../auth/api';
import extStyles from '../utils/styles.module.css';

function Blog() {
  const editorRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [mainData, setMainData] = useState({});
  const [cmntItem, setCommentItem] = useState({});
  const [open, setOpen] = useState(false);
  const [openCommentUpdate, setOpenCommentUpdate] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [blogId, setBlogId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [blogFilter, setBlogFilter] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedVisible, setSelectedVisibility] = useState('');
  const [selectedAdded, setAddedChange] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [mediaList, setMediaList] = useState([]);
  const [cmntList, setCmntList] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();

  const TABLE_NAME = ["blogs", "blog_filter", "media", "blog_comment"];

  const stripHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  useEffect(() => {
    fetchData(TABLE_NAME)
      .then(responseData => {
        setData(responseData.saklayen.blogs);
        setBlogFilter(responseData.saklayen.blog_filter);
        setMediaList(responseData.saklayen.media);
        setCmntList(responseData.saklayen.blog_comment);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);

      });
  }, [dataChanged]);

  const resetMainDataState = () => {
    setMainData({});
  }
  const addedPerson = [
    { id: 1, short: "saklayen", name: "Saklayen Ahmed" },
    { id: 2, short: "admin", name: "As Admin" },
  ]

  const StyledContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
  }));

  const FlexContainer = styled(Box)({
    display: 'flex',
    alignItems: isMobile ? 'flex-start' : 'flex-end',
    flexDirection: isMobile ? "column" : 'row',
  });

  const CommentContent = styled(Box)({
    flex: '6 0 0',
  });

  const ButtonContainer = styled(Box)({
    flex: '4 0 0',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    marginTop: isMobile ? "1rem" : "",
  });

  const GlobalStyle = styled(Box)({
    '& .custom-list-content ul': {
      marginLeft: '40px',
      marginBottom: '10px',
    },
    '& .custom-list-content li': {
      marginBottom: '5px',
    },
  });

  const handleEditorChange = (event, editorRef) => {
    if (!editorRef || !editorRef.current) {
      return;
    }
    const data = editorRef.current.getContent();
    setMainData(prevState => ({ ...prevState, description: data }));
  };

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setMainData((prevData) => {
      let newTitle = prevData.title;
      if (name === "title") {
        newTitle = value.replace(/\s+/g, "-").toLowerCase();
      }
      return {
        ...prevData,
        [name]: value,
        title_url: newTitle,
      };
    });
  };

  const handleDateSelect = (newDate) => {
    setMainData((prevData) => ({
      ...prevData,
      date_added: dayjs(newDate).format('YYYY-MM-DD'),
    }));
  };

  const handleFileChange = (event) => {
    const selectedValue = event.target.value;
    setMainData((prevData) => ({
      ...prevData,
      image: selectedValue,
    }));
    setSelectedFile(selectedValue);
  };

  const handleVisibilityChange = (event) => {
    const selectedValue = event.target.value;
    setMainData((prevData) => ({
      ...prevData,
      visibility: selectedValue,
    }));
    setSelectedVisibility(selectedValue);
  };
  const handleVisibilityChangeComment = (event) => {
    const selectedValue = event.target.value;
    setCommentItem((prevData) => ({
      ...prevData,
      visibility: selectedValue,
    }));
    setSelectedVisibility(selectedValue);
  };
  const handleAddedChange = (event) => {
    const selectedValue = event.target.value;
    setMainData((prevData) => ({
      ...prevData,
      added_by: selectedValue,
    }));
    setAddedChange(selectedValue);
  };

  const handleAdd = () => {
    const requestData = {
      table: TABLE_NAME[0],
      data: mainData
    };
    addData(requestData)
      .then(response => {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        const addData = [...data, mainData];
        setData(addData);
        setOpen(false);
        setIsAdding(false);
        setMainData({});
        setDataChanged(!dataChanged);
      })
      .catch(error => {
        console.error(error);
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      });
  }

  const handleDelete = (id) => {
    const requestData = {
      table: TABLE_NAME[0],
      id,
      action: 'delete',
    };

    deleteData(requestData)
      .then(response => {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        const deletedData = data.filter(item => item.id !== id);
        setData(deletedData);
        setOpen(false);
      })
      .catch(error => {
        console.error(error);
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      });
  }

  const handleCommentDelete = (id) => {
    const requestData = {
      table: TABLE_NAME[3],
      id,
      action: 'delete',
    };
    deleteData(requestData)
      .then(response => {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        const deletedData = cmntList.filter(item => item.id !== id);
        setCmntList(deletedData);
        setDeleteConfirm(false);
      })
      .catch(error => {
        console.error(error);
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      });
  }

  const handleCommentUpdate = (id) => {
    const existingData = cmntList.find(item => item.id === cmntItem.id);
    const isDataChanged = JSON.stringify(cmntItem) !== JSON.stringify(existingData);

    if (!isDataChanged) {
      setSnackbarMessage("No changes to save.");
      setSnackbarOpen(true);
      setOpen(false);
      return;
    }
    const requestData = {
      table: TABLE_NAME[3],
      data: cmntItem,
    };

    updateData(requestData)
      .then(response => {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        const updatedData = cmntList.map(item => {
          if (item.id === cmntItem.id) {
            return { ...item, ...cmntItem };
          }
          return item;
        });

        setCmntList(updatedData);
        setOpenCommentUpdate(false);
      })
      .catch(error => {
        console.error(error);
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      });

  }

  const handleSave = () => {
    const existingData = data.find(item => item.id === mainData.id);
    const isDataChanged = JSON.stringify(mainData) !== JSON.stringify(existingData);

    if (!isDataChanged) {
      setSnackbarMessage("No changes to save.");
      setSnackbarOpen(true);
      setOpen(false);
      return;
    }
    const requestData = {
      table: TABLE_NAME[0],
      data: mainData
    };

    updateData(requestData)
      .then(response => {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        const updatedData = data.map(item => {
          if (item.id === mainData.id) {
            return { ...item, ...mainData };
          }
          return item;
        });

        setData(updatedData);
        setOpen(false);
      })
      .catch(error => {
        console.error(error);
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      });
  }

  const handleClose = () => {
    setOpen(false)
    setViewOpen(false)
    setCommentOpen(false)
  };

  const handleConfirmClose = () => {
    setDeleteConfirm(false)

  };
  const handleCmntUpdateClose = () => {
    setOpenCommentUpdate(false)
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMainData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleCmntUpdateChange = (event) => {
    const { name, value } = event.target;
    setCommentItem((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setMainData((prevData) => ({
      ...prevData,
      category: selectedValue,
    }));
    setSelectedCategory(selectedValue);
  };


  // eslint-disable-next-line
  const renderViewDialog = () => {
    return (
      <Dialog open={viewOpen} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>[{mainData.category}] {mainData.title} </DialogTitle>
        <DialogContent>
          <StyledContainer>
            <GlobalStyle>
              <Typography
                className="custom-list-content"
                variant="body1"
                dangerouslySetInnerHTML={{ __html: mainData.description }}
              />
            </GlobalStyle>
          </StyledContainer>
          <Typography variant="body1" style={{ marginTop: '1rem' }} ><strong>Date Added:</strong> {mainData.date_added}</Typography>
          <Stack spacing={2} direction="row" style={{ marginTop: '2rem' }} justifyContent="space-between">
            <Button style={{ backgroundColor: 'maroon', color: 'white' }} variant="outlined" onClick={() => handleDelete(mainData.id)}><Delete /></Button>
            <Button variant="outlined" onClick={handleClose}>Close</Button>
          </Stack>

        </DialogContent>
      </Dialog>

    );
  };
  // eslint-disable-next-line
  const renderDeleteDialog = () => {
    return (
      <Dialog open={deleteConfirm} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Are you sure you want to delete the following item?</DialogTitle>
        <DialogContent>

          <Typography variant="body1" gutterBottom>
            <Typography variant="body2" sx={{ fontStyle: 'italic', fontSize: '20px', padding: '10px', border: '1px solid #eee' }} paragraph>{cmntItem.comments}</Typography>
            <Typography color="textSecondary">
              {cmntItem.name} • {new Date(cmntItem.date_added).toLocaleString()}
            </Typography>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="error"
            style={{ backgroundColor: 'maroon', color: 'white' }}
            startIcon={<Delete />}
            onClick={() => {
              handleCommentDelete(cmntItem.id);
            }}
          >
            Yes, Delete
          </Button>
          <Button
            variant="outlined"
            onClick={handleConfirmClose}
          >
            No, Cancel
          </Button>
        </DialogActions>
      </Dialog>

    );
  };
  // eslint-disable-next-line
  const renderCommentDialog = () => {
    return (
      <Dialog open={commentOpen} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle> {mainData.title} [{mainData.category}] </DialogTitle>
        <DialogContent>
          <>
            {cmntList.filter(cmnt => cmnt.blog_id === blogId).length > 0 ? (
              cmntList.filter(cmnt => cmnt.blog_id === blogId)
                .map(cmnt => (
                  <StyledContainer key={cmnt.id}>
                    <Typography variant="body1" sx={{ fontStyle: 'italic', fontSize: '20px' }} paragraph>{cmnt.comments}</Typography>
                    <Divider style={{ margin: '16px 0' }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FlexContainer>
                          <CommentContent>
                            <Typography variant="subtitle1"><strong>{cmnt.name}</strong></Typography>
                            <Typography variant="body2" color="textSecondary">
                              {new Date(cmnt.date_added).toLocaleString()}
                              {cmnt.email && cmnt.email.trim() !== '' && ` • ${cmnt.email}`}
                              {cmnt.mobile && cmnt.mobile.trim() !== '' && ` • ${cmnt.mobile}`}
                            </Typography>
                          </CommentContent>
                          <ButtonContainer>
                            <Button
                              variant="contained"
                              color="primary"
                              size='small'
                              onClick={() => {
                                setCommentItem(cmnt)
                                setOpenCommentUpdate(true)
                                setSelectedVisibility(cmnt.visibility);

                              }}
                            >
                              <Edit />
                            </Button>

                            <Button
                              variant="contained"
                              size='small'
                              style={{ backgroundColor: 'maroon', color: 'white' }}
                              onClick={() => {
                                setDeleteConfirm(true)
                                setCommentItem(cmnt)
                              }
                              }
                            >
                              {<Delete />}
                            </Button>

                          </ButtonContainer>
                        </FlexContainer>
                      </Grid>
                    </Grid>
                  </StyledContainer>
                ))) : (
              <Typography variant="body1">No comments available.</Typography>
            )}
          </>
          <Stack spacing={2} direction="row" style={{ marginTop: '2rem' }} justifyContent="flex-end">
            <Button variant="outlined" onClick={handleClose}>Close</Button>
          </Stack>
        </DialogContent>
      </Dialog>

    );
  };

  const renderCommentUpdateDialog = () => {
    return (
      <Dialog open={openCommentUpdate} onClose={handleCmntUpdateClose} maxWidth="md" fullWidth>
        <DialogTitle><span style={{ color: '#2065D1' }}>Update comments of</span> {cmntItem.name} [{new Date(cmntItem.date_added).toLocaleString()}]</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              marginTop: '1rem',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '1rem'
            }}
          >
            <TextField
              label="Comments"
              name="comments"
              multiline
              rows={5}
              value={cmntItem.comments}
              onChange={handleCmntUpdateChange}
              sx={{ gridColumn: 'span 2' }}
            />
            <TextField
              sx={{ gridColumn: isMobile ? 'span 2' : '' }}
              label="Name"
              name="name"
              value={cmntItem.name}
              onChange={handleCmntUpdateChange}
            />
            <TextField
              sx={{ gridColumn: isMobile ? 'span 2' : '' }}
              label="Email"
              name="email"
              value={cmntItem.email}
              onChange={handleCmntUpdateChange}
            />
            <TextField
              sx={{ gridColumn: isMobile ? 'span 2' : '' }}
              label="Mobile"
              name="mobile"
              value={cmntItem.mobile}
              onChange={handleCmntUpdateChange}
            />

            <FormControl sx={{ gridColumn: isMobile ? 'span 2' : '' }}>
              <InputLabel id="Visibility">Visibility</InputLabel>
              <Select
                labelId="Visibility"
                label="Visibility"
                value={selectedVisible}
                onChange={handleVisibilityChangeComment}
                name='visibility'
              >
                <MenuItem key={1} value={1}>Show</MenuItem>
                <MenuItem key={2} value={0}>Hide</MenuItem>
              </Select>
            </FormControl>
            <Stack spacing={2} direction="row" style={{ marginTop: '20px' }} justifyContent="flex-start">
              <Button variant="contained" onClick={() => handleCommentUpdate(cmntItem.id)}>{<Check />}</Button>
            </Stack>
            <Stack spacing={2} direction="row" style={{ marginTop: '20px' }} justifyContent="flex-end">
              <Button variant="outlined" onClick={handleCmntUpdateClose}>Close</Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  // eslint-disable-next-line
  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>{isAdding ? 'Add New Blog' : mainData.title}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              marginTop: '1rem',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '1rem'
            }}
          >
            <TextField
              label="Rank"
              name="rank"
              value={mainData.rank}
              onChange={handleTitleChange}
              sx={{ gridColumn: isMobile ? 'span 2' : '' }}

            />

            <FormControl sx={{ gridColumn: isMobile ? 'span 2' : '' }}>
              <InputLabel id="category">Category</InputLabel>
              <Select
                labelId="category"
                label="Category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                name='category'
              >
                {
                  blogFilter.map(item => (
                    <MenuItem key={item.id} value={item.category}>{item.text}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <TextField
              label="Title"
              name="title"
              sx={{ gridColumn: 'span 2' }}
              value={mainData.title}
              onChange={handleTitleChange}
            />
            <Box
              sx={{
                gridColumn: 'span 2',
                width: '100%'
              }}
            >
              <Editor
                apiKey='xq4fv0m3iwdl8ad5pndoc2206h0h8ibjx9pdwko1dyuzv05i'
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
                // initialValue={mainData.description}
                value={mainData.description}
                // onChange={() => setContent(editorRef.current.getContent())}
                onEditorChange={(evt, editor) => handleEditorChange(evt, editorRef)}
                init={{
                  height: 500,
                  branding: false,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | table link bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | image media |" +
                    "removeformat | fullscreen code | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />

            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date Added"
                name='date_added'
                value={dayjs(mainData.date_added)}
                renderInput={(params) => <TextField {...params} />}
                // sx={{ gridColumn: 'span 2' }}
                sx={{ gridColumn: isMobile ? 'span 2' : '' }}
                onChange={handleDateSelect}
              />
            </LocalizationProvider>

            <FormControl sx={{ gridColumn: isMobile ? 'span 2' : '' }}
            >
              <InputLabel id="Added By">Added By</InputLabel>
              <Select
                labelId="Added By"
                label="Added By"
                value={selectedAdded}
                onChange={handleAddedChange}
                name='added_by'
              >
                {
                  addedPerson.map(item => (
                    <MenuItem key={item.id} value={item.short}>{item.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl sx={{ gridColumn: isMobile ? 'span 2' : '' }}
            >
              <InputLabel id="Visibility">Visibility</InputLabel>
              <Select
                labelId="Visibility"
                label="Visibility"
                value={selectedVisible}
                onChange={handleVisibilityChange}
                name='visibility'
              >
                <MenuItem key={1} value={1}>Show</MenuItem>
                <MenuItem key={2} value={0}>Hide</MenuItem>

              </Select>
            </FormControl>
            <FormControl sx={{ gridColumn: isMobile ? 'span 2' : '' }}
            >
              <InputLabel id="image_logo">Image</InputLabel>
              <Select
                labelId="image_logo"
                label="Image"
                value={selectedFile}
                onChange={handleFileChange}
                name='image'
              >
                {
                  mediaList.map(item => (
                    <MenuItem key={item.id} value={item.file_name}>{item.file_text}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <Stack spacing={2} direction="row" style={{ marginTop: '20px' }} justifyContent="flex-start">
              {isAdding ? null : <Button style={{ backgroundColor: 'maroon', color: 'white' }} variant="outlined" onClick={() => handleDelete(mainData.id)} ><Delete /></Button>}
            </Stack>
            <Stack spacing={2} direction="row" style={{ marginTop: '20px' }} justifyContent="flex-end">
              <Button variant="outlined" onClick={handleClose}>Close</Button>
              <Button variant="contained" onClick={isAdding ? handleAdd : handleSave}>{isAdding ? 'Add' : <Check />}</Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '.3rem' }}>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
          setIsAdding(true);
          resetMainDataState();
        }}
        style={{ marginBottom: '1.3rem' }}>Add New</Button>
      <Button
        variant="outlined"
        onClick={() => {
          navigate('/blog-category')
        }}
        style={{
          marginBottom: '1rem',
          marginLeft: "20px"
        }}
      >
        Blog Category
      </Button>
      <TableContainer component={Paper}>
        {loading ? (
          <div className={extStyles.spinnerarea}>
            <CircularProgress />
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SL</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => (
                <TableRow key={item.id}>
                  <TableCell>{item.rank}</TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{stripHTML(item.description.substring(0, 100))}</TableCell>
                  <TableCell>
                    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                      <Button
                        onClick={() => {
                          setMainData(item);
                          setOpen(true);
                          setSelectedFile(item.image);
                          setAddedChange(item.added_by);
                          setSelectedCategory(item.category);
                          setSelectedVisibility(item.visibility);
                          setIsAdding(false)
                        }}
                      >
                        <Edit />
                      </Button>
                      <Button
                        onClick={() => {
                          setMainData(item);
                          setViewOpen(true);
                        }}
                      >
                        <RemoveRedEye />
                      </Button>
                      <Button
                        onClick={() => {
                          setBlogId(item.id);
                          setMainData(item);
                          setCommentOpen(true);
                        }}
                      >
                        <Message />
                      </Button>
                    </ButtonGroup>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onclose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOpen(false)}>
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      />
      {renderDialog()}
      {renderViewDialog()}
      {renderDeleteDialog()}
      {renderCommentUpdateDialog()}
      {renderCommentDialog()}
    </Container>
  );
}

export default Blog;
