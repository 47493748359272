import React, { useState, useEffect } from 'react';
import { Button, MenuItem, Select, Box, FormControl, Dialog, DialogContent, DialogTitle, InputLabel, Stack, Snackbar, Alert } from '@mui/material';
import CryptoJS from 'crypto-js';
import { generateCv, fetchData } from '../auth/api';

const MyCvComponent = () => {
    const [isCVLoading, setIsCVLoading] = useState(false);
    const [openCv, setOpenCv] = useState(false);
    const [data, setData] = useState([]);
    const [selectedSecure, setSelectedSecure] = useState('');
    const [mainData, setMainData] = useState({
        secure: '',
        cover: '',
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');


    const TABLE_NAME = ["cover_letter"];


    useEffect(() => {
        fetchData(TABLE_NAME)
            .then(responseData => {
                setData(responseData.saklayen.cover_letter);
            })
            .catch(error => {
                console.error('Error fetching data:', error);

            });
    }, []);

    const currentDate = () => {
        const date = new Date();

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}${month}${year}${hours}${minutes}${seconds}`;
    };

    const handleSecure = (event) => {
        const { name, value } = event.target;
        setMainData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleDownloadCV = async (e) => {
        e.preventDefault();
        setIsCVLoading(true);
        const date = new Date();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ciphertext = CryptoJS.MD5(minutes).toString();
        try {
            const requestData = {
                token: ciphertext,
                cover: mainData.cover,
                secure: mainData.secure,
            };
            const response = await generateCv(requestData);
            console.log(response);

            if (response.size < 50000) {
                setSnackbarMessage('Failed to generate PDF. Please try again.');
                setSnackbarOpen(true);
                setIsCVLoading(false);
                return;
            }

            const blob = new Blob([response], { type: 'application/pdf' });
            await new Promise(resolve => setTimeout(resolve, 0));
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            const dateStr = currentDate();
            a.download = `CV_of_Saklayen_Ahmed_${dateStr}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setIsCVLoading(false);
        }
    };

    const handleClose = () => {
        setOpenCv(false)
    };


    const renderDialog = () => {
        return (
            <Dialog open={openCv} onClose={handleClose}>
                <DialogTitle>Generate CV</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            marginTop: '1rem',
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '1rem'
                        }}
                    >
                        <FormControl sx={{ gridColumn: 'span 2' }}>
                            <InputLabel id="secure">Generate as Secure</InputLabel>
                            <Select
                                labelId="secure"
                                label="Generate as Secure"
                                name='secure'
                                onChange={handleSecure}
                                value={mainData.secure}
                            >
                                <MenuItem key={1} value={1}>Yes</MenuItem>
                                <MenuItem key={2} value={0}>No</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ gridColumn: 'span 2' }}>
                            <InputLabel id="cover">With Cover Letter</InputLabel>
                            <Select
                                labelId="cover"
                                label="With Cover Letter"
                                name='cover'
                                onChange={handleSecure}
                                value={mainData.cover}
                            >
                                <MenuItem key={1} value="none">None</MenuItem>

                                {
                                    data.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <Stack spacing={2} direction="row" style={{ marginTop: '20px' }} justifyContent="flex-end">
                            <Button variant="outlined" onClick={handleClose}>Close</Button>
                            <Button variant="contained" onClick={handleDownloadCV} disabled={isCVLoading}>{isCVLoading ? 'Generating...' : 'Download'}</Button>
                        </Stack>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    };


    return (
        <>
            <Button
                variant="outlined"
                style={{
                    marginTop: '1rem'
                }}
                onClick={() => {
                    setOpenCv(true)
                }
                }

            >
                Generate CV
            </Button>
            {renderDialog()}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert severity="error" onClose={() => setSnackbarOpen(false)}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default MyCvComponent;
